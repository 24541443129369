const ifNullUndefinedArray = (data) => {
  return [null, undefined].includes(data) ? [] : data;
};

/**
 * Take a stringified array of strings & convert back to array
 * @param {*} value
 * @returns
 */
const formatStringArray = (value) => {
  let text = value.split("[");
  text = text[1].split("]")[0];
  text = text.replaceAll('"', "");
  text = text.split(",");
  return text.map((it) => it.trim());
};

const translateRecTypeType = (type) => {
  type = type.toLowerCase();
  const typeMap = { sequential: "Rolling", ad_hoc: "Point-in-time" };
  return typeMap[type] ? typeMap[type] : type;
};

const handleTeamChange = (currentTeamId, urlTeamId, update) => {
  if (currentTeamId && urlTeamId && currentTeamId !== urlTeamId) {
    update();
  }
};

export { ifNullUndefinedArray, formatStringArray, translateRecTypeType, handleTeamChange };
