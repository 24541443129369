import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Text, Button } from "@fundrecs/ui-library";
import { MainContainer, ManageLayout, PageTitleArea } from "../layout/Layout";
import { DeleteFile } from "./DeleteFile";
import { useStore } from "../../store/Store.js";
import { PATH } from "../../utils/urls";
import { UploadedFileDetails } from "./UploadedFileDetails";
import { AUTHORITIES, STATUS } from "../../utils/enums";
import { AuthWrapper } from "../AuthorizationWrapper";
import { stringifyDate } from "../../utils/dates";
import { handleTeamChange } from "../../utils/utils";

const UploadedFile = observer(({ deleteDisabled = false }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [initialised, setInitialised] = useState(false);
  const { uploadedFileStore, rolesStore, teamStore } = useStore();
  const navigate = useNavigate();
  const [file, setFile] = useState({ fileName: "" });
  const teamId = searchParams.get("teamId") ? searchParams.get("teamId") : null;
  const team = teamStore.getSelectedTeam();
  const fileId = searchParams.get("fileId") ? searchParams.get("fileId") : null;

  const getFileById = async (fileId) => {
    const response = await uploadedFileStore.getUploadedFileById(teamId, fileId);

    if (response.status === 200) {
      setFile(response.data);
    }

    const response2 = await uploadedFileStore.getUploadedFileRowCountsById(teamId, fileId);
    if (response2.status === 200) {
      setFile({ ...response.data, ...response2.data });
    }
  };

  if (!initialised && fileId) {
    getFileById(fileId);
    setInitialised(true);
  }

  useEffect(() => {
    const date = file && file.startTime ? new Date(file.startTime) : new Date();
    handleTeamChange(team.id, Number(teamId), () => {
      navigate(`${PATH.UPLOADS}?teamId=${teamId}&date=${stringifyDate(date)}`);
    });
  }, [file, teamId, team, navigate]);

  const pollStatus = async (statuses = [STATUS.SENT_TO_DATA.status, STATUS.DRAFT.status]) => {
    //Check that the user is still on the uploaded file page
    if (window.location.href.includes("/uploadedFile?")) {
      const resp = await uploadedFileStore.getUploadedFileById(teamId, fileId);
      if (resp.status === 200 && !statuses.includes(resp.data.fileStatus)) {
        window.location.reload();
      } else {
        setTimeout(() => {
          pollStatus(statuses);
        }, 10000);
      }
    }
  };

  if ([STATUS.SENT_TO_DATA.status, STATUS.DRAFT.status].includes(file.fileStatus)) {
    pollStatus();
  }

  const reimportData = async () => {
    const body = { list: [{ id: file.fileId, version: file.fileVersion }] };
    const resp = await uploadedFileStore.reimportData(teamId, body);
    if (resp.status === 200) {
      window.location.reload();
    }
  };

  return (
    <MainContainer>
      <PageTitleArea
        title={file.fileName}
        description="Uploaded data to Fusion"
        borderBottom={false}
        props={
          <>
            <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_REC_UPLOAD_FILE])}>
              <Button
                size="md"
                onClick={() => {
                  reimportData();
                }}
                disabled={STATUS.IMPORTED.status !== file.fileStatus}
              >
                <Text size="sm" weight="medium">
                  Re-import data
                </Text>
              </Button>
            </AuthWrapper>
            <span className="pl-8"></span>
            <DeleteFile teamId={teamId} fileId={file.fileId} fileVersion={file.fileVersion} disabled={deleteDisabled} />
          </>
        }
      />
      <ManageLayout headerTabs={""} mainContentToolbar={""} panelHeader={<></>} pageTitleArea={<></>} panelTabs={<></>}>
        <UploadedFileDetails selectedFile={file} columnNumber={3} />
      </ManageLayout>
    </MainContainer>
  );
});

export { UploadedFile };
