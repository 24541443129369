import { useState } from "react";
import { observer } from "mobx-react-lite";
import { Text, Input } from "@fundrecs/ui-library";
import { SidePanelSection } from "./SidePanelSection";
import { ReactComponent as SubAccountsIcon } from "../../../../icons/subAccounts.svg";
import { Badge } from "../../../layout/Layout";

const SubAccountSection = observer(({ panelOpen, openOnClick, updateUrl = null, allSubAccounts = [], rowCounts = [] }) => {
  const [searchFilter, setSearchFilter] = useState("");
  const createLink = (text, subAccount, note, colour) => {
    let style = { fontWeight: "700", cursor: "pointer", backgroundColor: "lightgray", color: "black", width: `${text.length * 15}px` };
    if (colour === "green") {
      style.backgroundColor = "#E8F6EE"; //green
      style.color = "#15A251";
    } else if (colour === "blue") {
      style.backgroundColor = "#E9EFFF"; //blue
      style.color = "#1E5EFF";
    }

    return (
      <span
        onClick={() => {
          if (updateUrl !== null) {
            updateUrl(subAccount, note);
          }
        }}
      >
        <Badge text={text} style={style} />
      </span>
    );
  };

  return (
    <SidePanelSection
      panelOpen={panelOpen}
      sectionOpen={panelOpen === "subAccounts"}
      openOnClick={openOnClick}
      title="Sub accounts"
      icon={<SubAccountsIcon />}
      accordianId={"side-panel-sub-account-accordian"}
    >
      <div className="pt-8 pl-8 pr-8 pb-8 mb-24">
        <Input
          onChange={(event) => {
            setSearchFilter(event.target.value);
          }}
          value={searchFilter}
          search={true}
          placeholderText="Search"
        />
      </div>
      {allSubAccounts.map((subAccount) => {
        const counts = rowCounts.find((it) => it.id === subAccount.id);
        return searchFilter.length === 0 || subAccount.name.toLowerCase().includes(searchFilter.toLowerCase()) ? (
          <div className="d-flex justify-content-between mb-8">
            <div title={subAccount.name} className="ellipsize-text" style={{ maxWidth: "70%" }}>
              <Text variant="secondary" size="sm" weight="medium">
                {subAccount.name}
              </Text>
            </div>
            <div>
              {createLink(counts ? counts.unprocessedRowsCount : 0, subAccount, "Unprocessed", "blue")}
              {createLink(counts ? counts.processedRowsCount : 0, subAccount, "Processed", "green")}
            </div>
          </div>
        ) : (
          ""
        );
      })}
    </SidePanelSection>
  );
});

export { SubAccountSection };
