import { useState, useRef, useContext, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Text, R, C10, Toggle } from "@fundrecs/ui-library";
import { ManageLayout, TabsLayout } from "../layout/Layout.js";
import { CATEGORY_FILTER_COLUMN, STATUS } from "../../utils/enums.js";
import { ROW_FETCH_LIMIT, ALL_ROWS, getLastPageNo } from "../../utils/rowBatchUtils.js";
import { Table } from "../ag-grid/Ag-grid";
import { useStore } from "../../store/Store.js";
import { getDateStringFromTimestamp } from "../../utils/dates";
import { updateRowPageUrl } from "../../utils/urls.js";
import { MatchPreviewPanel } from "./MatchPreviewPanel.js";
import { OpenRecToolbar } from "./reconciliationToolbars.js";
import { RunningMatchingRulesPlaceholder } from "./matchingRules/RunningMatchingRulesPlaceholder.js";
import { formatMatchedRowsForTable, formatUnmatchedRowsForTable } from "./recTableLogic.js";
import { PendingGlobalMappingsAlert } from "../globalMapping/PendingGlobalMappingsAlert.js";
import { ColumnViewEditor } from "./toolbars/ColumnViewEditor.js";
import { ifNullUndefinedArray } from "../../utils/utils.js";
import { DifferenceCalculator } from "./DifferenceCalculator.js";
import { MatchingContext, MatchingDispatchContext, SET_AGGREGATORS } from "./matchingReducer.js";
import { RowBatchSelector } from "./toolbars/RowBatchSelector.js";
import { CategoryChart } from "./toolbars/CategoryChart.js";
import { ToolBarButton } from "./toolbars/ToolBarButton.js";
import { ReactComponent as DownloadIcon } from "../../icons/download.svg";
import { ProcessedRowsToolbar } from "./toolbars/ProcessedRowsToolbar.js";
import { AnimatedPanelToggleButton } from "../reusable/AnimatedPanel/AnimatedPanelToggleButton.js";
import { ReactComponent as ClearFiltersIcon } from "../../icons/clear-filters.svg";
import { RecSidePanel } from "./sidePanels/RecSidePanel.js";
import { RowGroupDetailsPanel } from "./sidePanels/RowGroupDetailsPanel.js";
import { RecDetailsPanel } from "./sidePanels/recDetailsSidePanel/RecDetailsPanel.js";

/**
 * View for the open rec page
 */
const OpenRecView = observer(
  ({
    recType,
    selectedAccount,
    uploadedFiles = [],
    pollRecStatus,
    globalMappingsEnabledForAccount,
    globalMapping,
    subAccounts = [],
    rowGroupSchema = [],
    diffCalcHidden = false,
  }) => {
    const unprocessedText = "Unprocessed";
    const processedText = "Processed";
    const rowTableTabs = [
      { index: 0, text: unprocessedText },
      { index: 1, text: processedText },
    ];
    const { recStore, teamStore, recTypeStore, matchingRuleStore, tmoAggregatorStore, uiStore } = useStore();
    const unprocessedTableRef = useRef(null);
    const processedTableRef = useRef(null);
    const navigate = useNavigate();
    const rec = recStore.getSelectedRec();
    const [unprocessedRows, setUnprocessedRows] = useState({ columnDefs: [], rows: [] });
    const [processedRows, setProcessedRows] = useState({ columnDefs: [], rows: [], chartData: [] });
    const [matchPreview, setMatchPreview] = useState(null);
    const [matchPreviewRowData, setMatchPreviewRowData] = useState({ columns: [], rows: null });
    const [differencesRowData, setDifferencesRowData] = useState(null);
    const [initialised, setInitialised] = useState(false);
    const [selectedSubAccount, setSelectedSubAccount] = useState({});
    const [allRowsMatched, setAllRowsMatched] = useState({});
    const [matchingRuleId, setMatchingRuleId] = useState(null);
    const [rowGroupDetailsOpen, setRowGroupDetailsOpen] = useState(false);
    const [selectedRowGroups, setSelectedRowGroups] = useState([]);
    const [recDetailsOpen, setRecDetailsOpen] = useState(null);
    const [rowCounts, setRowCounts] = useState([]);
    const [matchingRulesRunning, setMatchingRulesRunning] = useState(false);
    const [manualCategorizationOptions, setManualCategorizationOptions] = useState(null);
    const [submitRecDisabled, setSubmitRecDisabled] = useState(true);
    const [globalMappingEntries, setGlobalMappingEntries] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const compactRowHeight = 28;
    const compactHeaderHeight = 32;
    const [rowHeight, setRowHeight] = useState(compactRowHeight);
    const [displayNumberOfRows, setDisplayNumberOfRows] = useState(ROW_FETCH_LIMIT);
    const [rowCountsForMatchingTable, setRowCountsForMatchingTable] = useState(null);
    const [unmatchedColumns, setUnmatchedColumns] = useState([]);
    const dispatch = useContext(MatchingDispatchContext);
    const selectedAggregators = useContext(MatchingContext)["aggregators"];

    const [searchParams, setSearchParams] = useSearchParams();
    const accountId = Number(searchParams.get("accId"));
    const recId = Number(searchParams.get("recId"));
    const recTypeId = Number(searchParams.get("recTypeId"));
    const teamId = Number(searchParams.get("teamId"));
    const subAccountUrlParam = Number(searchParams.get("subAccId"));
    const tabUrlParam = searchParams.get("tab");

    const recTypes = ifNullUndefinedArray(recTypeStore.getRecTypes());
    const matchingRules = matchingRuleStore.getMatchingRules();

    const [selectedTab, setSelectedTab] = useState(tabUrlParam);
    const [existingParams, setExistingParams] = useState({
      teamId: teamId,
      recTypeId: recTypeId,
      accId: accountId,
      recId: recId,
      subAccId: subAccountUrlParam,
      tab: tabUrlParam,
    });

    const checkboxHeader = {
      field: "",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      headerCheckboxSelectionFilteredOnly: true,
      width: 50,
      maxWidth: 50,
    };

    const replaceUrl = (updatedParams) => {
      setExistingParams(updatedParams);
      updateRowPageUrl(navigate, updatedParams, true);
    };

    const changeTab = (tab) => {
      if (selectedSubAccount != null && Object.keys(selectedSubAccount).length) {
        setSelectedTab(tab);
        updateTable(tab, selectedSubAccount, allRowsMatched);
      }
    };

    const selectSubAccount = async (subAccount, allRowsMatched) => {
      await matchingRuleStore.fetchMatchingRules(teamId, recTypeId, accountId, subAccount.id);
      setSelectedSubAccount(subAccount);
      updateTable(selectedTab, subAccount, allRowsMatched);
    };

    const selectSubAccountAndTab = async (subAccount, tab) => {
      await matchingRuleStore.fetchMatchingRules(teamId, recTypeId, accountId, subAccount.id);
      setSelectedSubAccount(subAccount);
      setSelectedTab(tab);
      updateTable(tab, subAccount, allRowsMatched);
    };

    const updateTable = async (selectedTab, subAccount, allRowsMatched, recVersion = rec.version, numberOfRows = displayNumberOfRows) => {
      updateTableState();
      if (numberOfRows !== displayNumberOfRows) {
        setDisplayNumberOfRows(numberOfRows);
      }
      if (selectedTab === unprocessedText) {
        await populateUnmatchedTable(subAccount, allRowsMatched, recVersion, numberOfRows);
      } else if (selectedTab === processedText) {
        await getRowGroupsForSubAccount(subAccount, selectedTab, recVersion, numberOfRows);
      }
    };

    const updateTableState = () => {
      setProcessedRows({ ...processedRows, rows: null });
      setUnprocessedRows({ ...unprocessedRows, rows: null });
    };

    const populateUnmatchedTable = async (subAccount, allRowsMatched, recVersion, numberOfRows) => {
      const teamId = teamStore.getSelectedTeam().id;
      numberOfRows = numberOfRows === ALL_ROWS ? rowCountsForMatchingTable.unprocessedRowsCount : numberOfRows;
      let unmatchedRows = { rows: [], schema: [] };

      /*
      const responses = await fetchBatches(numberOfRows, (pageNo) => recStore.getUnmatchedRows(teamId, recId, subAccount.uuid, recVersion, pageNo));
      let rows = [];
      responses.forEach((resp) => (rows = [...rows, ...resp.rows]));
      unmatchedRows = { schema: responses && responses.length ? responses[0]["schema"] : [], rows: rows };
      */
      //Above commented logic sends the requests concurently, rather than the loop below which sends them consecutively
      for (let pageNo = 0; pageNo <= getLastPageNo(numberOfRows); pageNo++) {
        if (pageNo === 0) {
          unmatchedRows = await recStore.getUnmatchedRows(teamId, recId, subAccount.uuid, recVersion, pageNo);
        } else {
          const resp = await recStore.getUnmatchedRows(teamId, recId, subAccount.uuid, recVersion, pageNo);
          unmatchedRows.rows = [...unmatchedRows.rows, ...resp.rows];
        }
      }

      const tableData = formatUnmatchedRowsForTable(unmatchedRows);
      setUnprocessedRows({ columnDefs: [checkboxHeader].concat(tableData.columns), rows: tableData.rows });
      setAllRowsMatched({ ...allRowsMatched, [subAccount.uuid]: unmatchedRows.length < 1 });
    };

    /**
     * Gets all rowGroups grouped by subAccount and Note
     * Populates Array tableDataForSubAccounts with object {subAccountName: [Array of rowGroups ordered by classifier note]}
     * @param {*} classifierNotes
     */
    const getRowGroupsForSubAccount = async (subAccount = null, selectedTabText, recVersion, numberOfRows) => {
      if (subAccount != null && Object.keys(subAccount).length && selectedTabText === processedText && rowGroupSchema.length) {
        numberOfRows = numberOfRows === ALL_ROWS ? rowCountsForMatchingTable.rowGroupsCount : numberOfRows;
        let rowGroups = [];

        /*
        const responses = await fetchBatches(numberOfRows, (pageNo) => recStore.getRowGroupsForSubAccount(teamId, recId, subAccount.uuid, recVersion, pageNo));
        let rowGroups = [];
        responses.forEach((resp) => (rowGroups = [...rowGroups, ...resp]));
        */
        //Above commented logic sends the requests concurently, rather than the loop below which sends them consecutively
        for (let pageNo = 0; pageNo <= getLastPageNo(numberOfRows); pageNo++) {
          const resp = await recStore.getRowGroupsForSubAccount(teamId, recId, subAccount.uuid, recVersion, pageNo);
          rowGroups = [...rowGroups, ...resp];
        }

        const tableData = formatMatchedRowsForTable(rowGroupSchema, rowGroups, matchingRules, true);
        setProcessedRows({ columnDefs: [checkboxHeader].concat(tableData.columns), ...tableData });
      }
    };

    const getRowsForRowGroup = async (rowGroupId) => {
      const rows = await recStore.getRowsForRowGroup(teamId, rowGroupId);
      return rows;
    };

    const closePreview = () => {
      updateDifferences(selectedAggregators);
      deselectAllRows();
      setMatchPreview(null);
      setMatchPreviewRowData({ columns: [], rows: null });
      setMatchingRuleId(null);
      setManualCategorizationOptions({});
      setGlobalMappingEntries(null);
    };

    const updateMatchPreview = (previewType, rowGroupData) => {
      if (rowGroupData) {
        setMatchPreview(previewType);
        setMatchPreviewRowData(formatMatchedRowsForTable(rowGroupData.schema, rowGroupData.rowGroups, matchingRules));
        setGlobalMappingEntries(rowGroupData.globalMappingEntries);
      }
    };

    const removeMatchedRows = async () => {
      const selectedRows =
        processedTableRef && processedTableRef.current && processedTableRef.current.api ? processedTableRef.current.api.getSelectedRows() : [];
      if (selectedRows.length < 1) {
        uiStore.addNotification("error", "Please select at least 1 row");
      } else {
        const reqBody = selectedRows.map((it) => it.id);
        const recVersion = await recStore.removeMatch(teamStore.getSelectedTeam().id, recId, selectedSubAccount.uuid, rec.version, reqBody);
        if (recVersion) {
          const updatedAllRowsMatched = { ...allRowsMatched, [selectedSubAccount.uuid]: false };
          updateTable(selectedTab, selectedSubAccount, updatedAllRowsMatched, recVersion);
          setAllRowsMatched(updatedAllRowsMatched);
          refreshRowCounts();
          canSubmit();
        }
      }
    };

    const submitRec = async () => {
      const rec = recStore.getSelectedRec();
      recStore.submitRec(teamId, rec.id, rec.version);
    };

    const matchSuccessUpdateTable = (recVersion) => {
      closePreview();
      refreshRowCounts();
      updateTable(selectedTab, selectedSubAccount, allRowsMatched, recVersion);
      canSubmit();
    };

    const onClickExpandRows = async (params) => {
      let rows = [];
      if (params.data && params.data.id) {
        const resp = await getRowsForRowGroup(params.data.id);
        rows = formatUnmatchedRowsForTable(resp).rows;
      }
      return rows;
    };

    const canSubmit = async () => {
      const resp = await recStore.canSubmit(teamId, recId);
      if (resp.status === 200 && !matchingRulesRunning) {
        setSubmitRecDisabled(!resp.data);
      }
    };

    const recStatus = rec.fourEyes && rec.fourEyes.status ? rec.fourEyes.status : "";
    const processedRowButtonsDisabled = matchingRulesRunning || recStatus !== STATUS.DRAFT.status || selectedTab !== processedText;
    const submitMatchedRowsDisabled = matchingRulesRunning || !matchPreview || !matchPreviewRowData.rows.length;
    if (
      [STATUS.IMPORTING_DATA.status, STATUS.IN_PROGRESS.status, STATUS.REPORT_CLEAR.status, STATUS.REPORT_CREATE.status].includes(recStatus) &&
      !matchingRulesRunning
    ) {
      setMatchingRulesRunning(true);
      pollRecStatus();
    }

    const refreshCountsForAllSubAccounts = async () => {
      const rowCountResponse = await recStore.fetchRowCounts(teamId, recId);
      setRowCounts(rowCountResponse);
      return rowCountResponse;
    };

    const refreshCountsForMatchingTable = async (subAccount = selectedSubAccount) => {
      const rowCountForTableResponse = await recStore.fetchRowCountForTable(teamId, recId, subAccount.id);
      setRowCountsForMatchingTable(rowCountForTableResponse.length ? rowCountForTableResponse[0] : null);
      return rowCountForTableResponse;
    };

    const refreshRowCounts = async (subAccount = selectedSubAccount) => {
      refreshCountsForAllSubAccounts();
      refreshCountsForMatchingTable(subAccount);
    };

    const getFirstSubAccountWithUnprocessedRows = async () => {
      let subAccountWithUnmatchedRows = null;
      let subAccountWithProcessedRows = null;
      const rowCountResponse = await refreshCountsForAllSubAccounts(teamId, recId);
      let subAccount = subAccounts[0];
      let tab = processedText;

      //Iterate through all counts per sub-account, find the frst sub-account with unprocessed rows
      for (let i = 0; i < rowCountResponse.length; i++) {
        if (rowCountResponse[i]["unprocessedRowsCount"] > 0 && !subAccountWithUnmatchedRows) {
          const subAccount = subAccounts.find((it) => it.name === rowCountResponse[i]["name"]);
          subAccountWithUnmatchedRows = subAccount ? subAccount : null;
        } else if (rowCountResponse[i]["processedRowsCount"] > 0 && !subAccountWithProcessedRows) {
          const subAccount = subAccounts.find((it) => it.name === rowCountResponse[i]["name"]);
          subAccountWithProcessedRows = subAccount ? subAccount : null;
        } else if (subAccountWithUnmatchedRows && subAccountWithProcessedRows) {
          break;
        }
      }
      if (subAccountWithUnmatchedRows) {
        subAccount = subAccountWithUnmatchedRows;
        tab = unprocessedText;
      } else if (subAccountWithProcessedRows) {
        subAccount = subAccountWithProcessedRows;
      }
      refreshCountsForMatchingTable(subAccount);
      selectSubAccountAndTab(subAccount, tab);
    };

    const initaliseSubAccounts = async () => {
      setInitialised(true);
      if (!subAccounts.length) {
        const fundName = selectedAccount ? selectedAccount.name : "this fund";
        uiStore.addNotification("error", `No sub accounts enabled for ${fundName} and ${recType.name}`);
      } else {
        //Ensure the clients, accounts & sub accounts have been fetched
        const clientsAccountsPerRecType = recTypeStore.getClientsAccountsPerRecType();
        if (!clientsAccountsPerRecType.length) {
          await recTypeStore.fetchClientsAccountsPerRecType(teamId, recTypeId);
        }
        let allRowsMatched = {};
        subAccounts.forEach((subAccount) => {
          allRowsMatched[subAccount.uuid] = false;
        });
        const subAccountFromUrl = subAccounts.find((acc) => acc.id === subAccountUrlParam);
        const subAccount = subAccountFromUrl ? subAccountFromUrl : subAccounts[0];
        setAllRowsMatched(allRowsMatched);

        //If tab exists in the URL load that tab and subAccount
        //else load first unmatched rows found on a sub account, else load processed view
        if (selectedTab) {
          refreshRowCounts(subAccount);
          selectSubAccount(subAccount, allRowsMatched);
        } else {
          getFirstSubAccountWithUnprocessedRows();
        }

        canSubmit();

        const aggregatorResponse = await tmoAggregatorStore.getTmoAggregatorsForRecType(teamId, recTypeId);
        await recTypeStore.fetchTmosForRecType(teamId, recTypeId);
        if (aggregatorResponse.status === 200) {
          const aggregatorDefault = {};
          aggregatorResponse.data.forEach((agg) => {
            if (aggregatorDefault[agg.tmoId] === undefined) {
              aggregatorDefault[agg.tmoId] = agg;
            }
          });
          dispatch({ type: SET_AGGREGATORS, aggregators: aggregatorDefault });
        }
        const unmatchedRows = await recStore.getUnmatchedRows(teamStore.getSelectedTeam().id, recId, subAccount.uuid, rec.version);
        const columns = formatUnmatchedRowsForTable(unmatchedRows).columns;
        setUnmatchedColumns(columns);
      }
    };

    //RecTypes is the last step in the data loaded when the app is initilaised, and is required for the page.
    if (recTypes.length && rowGroupSchema.length && !initialised) {
      initaliseSubAccounts();
    }

    const getGridRef = () => {
      return selectedTab === unprocessedText ? unprocessedTableRef : processedTableRef;
    };

    const clearFilters = () => {
      const gridRef = getGridRef();
      gridRef.current.api.setFilterModel(null);
    };

    const toggleRowHeight = () => {
      const gridRef = getGridRef();
      const standardHeight = 40;
      const newHeight = rowHeight === compactRowHeight ? standardHeight : compactRowHeight;
      setRowHeight(newHeight);
      gridRef.current.api.forEachNode((rowNode) => {
        rowNode.setRowHeight(newHeight);
      });
      gridRef.current.api.onRowHeightChanged();
      gridRef.current.api.setHeaderHeight(rowHeight === compactRowHeight ? standardHeight : compactHeaderHeight);
    };

    const deselectAllRows = () => {
      const gridRef = getGridRef();
      const rows = gridRef.current.api.getSelectedNodes();
      rows.forEach((row) => {
        row.setSelected(false);
      });
    };

    const createFileName = () => {
      const fileName = `${recType.name} reconciliation (${selectedTab} rows)- ${selectedAccount.name}, ${
        selectedSubAccount.name
      } - ${getDateStringFromTimestamp(rec.startDate)}`;
      const fullStopsRemoved = fileName.replaceAll(".", "");
      return fullStopsRemoved;
    };

    const updateDifferences = async (aggregators) => {
      dispatch({ type: SET_AGGREGATORS, aggregators: aggregators });
      const fetchDiffCalc = async () => {
        const selectedRows = unprocessedTableRef.current.api.getSelectedRows().map((row) => row.id);
        if (selectedRows.length && selectedRows.length <= 20) {
          let data = {
            aggregatorSet: Object.values(aggregators).map((it) => it.id),
            categoryId: null,
            tagSet: [],
            toBePersisted: false,
            proceedAsRows: false,
            comment: null,
            rowIdSet: selectedRows,
          };
          let response = { status: null };
          response = await recStore.manuallyCreateRowGroup(teamStore.getSelectedTeam().id, rec.id, rec.version, data);

          if (response.status === 200 && response.data && unprocessedTableRef.current.api.getSelectedRows().length) {
            setDifferencesRowData(formatMatchedRowsForTable(response.data.schema, response.data.rowGroups, matchingRules));
          } else {
            setDifferencesRowData(null);
          }
        } else if (selectedRows.length === 0 || selectedRows.length > 20) {
          setDifferencesRowData(null);
        }
      };

      if (
        !diffCalcHidden &&
        unprocessedTableRef &&
        unprocessedTableRef.current &&
        unprocessedTableRef.current.api &&
        unprocessedTableRef.current.api.getSelectedRows().length
      ) {
        setDifferencesRowData({ columns: differencesRowData && differencesRowData.columns ? differencesRowData.columns : [], rows: null });
        setTimeout(async () => {
          await fetchDiffCalc();
        }, "200");
      }
    };

    const renderRowCount = useMemo(() => {
      return rowCountsForMatchingTable
        ? selectedTab === unprocessedText
          ? rowCountsForMatchingTable.unprocessedRowsCount
          : selectedTab === processedText
          ? rowCountsForMatchingTable.rowGroupsCount
          : ""
        : "";
    }, [selectedTab, rowCountsForMatchingTable]);

    const downloadTable = () => {
      const gridRef = selectedTab === unprocessedText ? unprocessedTableRef : processedTableRef;
      gridRef.current.api.exportDataAsCsv({ fileName: createFileName() });
    };

    const updateSelectedCategory = (category) => {
      setSelectedCategory(category);
      const gridRef = getGridRef();
      let columnState = gridRef.current.columnApi.getColumnState();
      let filterModel = gridRef.current.api.getFilterModel();

      if (category === null) {
        delete filterModel[CATEGORY_FILTER_COLUMN];
      } else {
        filterModel[CATEGORY_FILTER_COLUMN] = {
          filterType: "text",
          type: "equals",
          filter: category,
        };
      }

      //Unsure why, but setting setSelectedCategory has the effect of removing any column settings, so we're reapplying them here
      setTimeout(() => {
        gridRef.current.columnApi.applyColumnState({
          state: columnState,
          applyOrder: true,
        });
        gridRef.current.api.setFilterModel(filterModel);
      }, 0);
    };

    const editComments = () => {
      if (processedTableRef && processedTableRef.current && processedTableRef.current.api && processedTableRef.current.api.getSelectedRows().length) {
        setSelectedRowGroups(processedTableRef.current.api.getSelectedRows());
        setRowGroupDetailsOpen(!rowGroupDetailsOpen);
      } else {
        uiStore.addNotification("error", "Please select at least 1 row");
      }
    };

    const spinnerText = {
      [STATUS.REPORT_CLEAR.status]: "Reopening reconciliation",
      [STATUS.REPORT_CREATE.status]: "Approving reconciliation",
      [STATUS.IN_PROGRESS.status]: "In progress",
      [STATUS.IMPORTING_DATA.status]: "Importing data",
    };

    const uiDisabled = matchingRulesRunning || recStatus !== STATUS.DRAFT.status;

    return (
      <>
        <ManageLayout
          headerTabs={
            <TabsLayout
              tabs={rowTableTabs.map((tab) => {
                return {
                  text: tab.text,
                  onClick: () => {
                    replaceUrl({ ...existingParams, tab: tab.text });
                    changeTab(tab.text);
                  },
                };
              })}
              activeTab={selectedTab === processedText ? 1 : 0}
            />
          }
          rightToolbar={
            <OpenRecToolbar
              gridRef={selectedTab === unprocessedText ? unprocessedTableRef : processedTableRef}
              updateTable={() => updateTable(selectedTab, selectedSubAccount, allRowsMatched)}
              selectedTab={selectedTab}
              processedRowButtonsDisabled={processedRowButtonsDisabled}
              removeMatchedRows={removeMatchedRows}
              loading={false}
              submitRec={submitRec}
              submitRecDisabled={submitRecDisabled}
              uiDisabled={uiDisabled}
              setMatchingRulesRunning={setMatchingRulesRunning}
              rec={rec}
              pollRecStatus={pollRecStatus}
              updateMatchPreview={updateMatchPreview}
              setMatchingRuleId={setMatchingRuleId}
              setManualCategorizationOptions={setManualCategorizationOptions}
              matchingRules={matchingRuleStore.getMatchingRules()}
              subAccountId={selectedSubAccount.id}
              teamId={teamId}
            />
          }
          panelHeader={<></>}
          pageTitleArea={<></>}
          topToolbar={
            <>
              <R props="mt-8">
                <C10 props="ml-16">
                  <Text size="lg" weight="bold">
                    {selectedSubAccount.name}
                  </Text>
                </C10>
              </R>
            </>
          }
        >
          <>
            {!matchingRulesRunning ? "" : <RunningMatchingRulesPlaceholder text={spinnerText[recStatus] ? spinnerText[recStatus] : "Running matching rules"} />}
            <PendingGlobalMappingsAlert
              teamId={teamId}
              recTypeId={recTypeId}
              accountId={accountId}
              globalMappingId={globalMappingsEnabledForAccount ? globalMapping.id : null}
            />
            <MatchPreviewPanel
              gridRef={selectedTab === unprocessedText ? unprocessedTableRef : processedTableRef}
              matchPreview={matchPreview}
              matchPreviewRowData={matchPreviewRowData.rows}
              closePreview={closePreview}
              submitMatchedRowsDisabled={submitMatchedRowsDisabled}
              loading={false}
              headers={matchPreviewRowData.columns}
              matchingRuleId={matchingRuleId}
              manualCategorizationOptions={manualCategorizationOptions}
              setMatchingRulesRunning={setMatchingRulesRunning}
              matchSuccessUpdateTable={matchSuccessUpdateTable}
              rec={rec}
              globalMappingEntries={globalMappingEntries}
            />
            <DifferenceCalculator
              show={!diffCalcHidden && selectedTab === unprocessedText && matchPreview === null && differencesRowData && differencesRowData.columns}
              differencesRowData={differencesRowData}
              updateDifferences={updateDifferences}
            />
            {selectedTab === processedText ? (
              <CategoryChart rowCounts={processedRows.chartData} selectedCategory={selectedCategory} updateSelectedCategory={updateSelectedCategory} />
            ) : (
              ""
            )}

            <div className="d-flex justify-content-between pt-16 pb-8 pr-0">
              <div>
                <ColumnViewEditor
                  gridRef={selectedTab === unprocessedText ? unprocessedTableRef : processedTableRef}
                  recTypeId={recTypeId}
                  teamId={teamId}
                  selectedTab={selectedTab}
                />
              </div>
              <RowBatchSelector
                rowCount={renderRowCount}
                displayNumberOfRows={displayNumberOfRows}
                fetchRows={async (option) => {
                  await updateTable(selectedTab, selectedSubAccount, allRowsMatched, rec.version, option);
                }}
              />
              <div className="d-flex justify-content-end" style={{ width: "30%", alignItems: "center" }}>
                <ToolBarButton icon={<ClearFiltersIcon className="btn-xs-svg" />} onClick={clearFilters} hoverText="Clear filters" />
                {selectedTab === processedText ? (
                  <>
                    <ProcessedRowsToolbar
                      processedTableRef={processedTableRef}
                      updateTable={() => updateTable(selectedTab, selectedSubAccount, allRowsMatched)}
                      rowGroupDetailsOpen={rowGroupDetailsOpen}
                      setRowGroupDetailsOpen={setRowGroupDetailsOpen}
                      editComments={editComments}
                      uiDisabled={uiDisabled}
                    />
                  </>
                ) : (
                  ""
                )}
                <ToolBarButton icon={<DownloadIcon />} onClick={downloadTable} hoverText="Download table" />
                <span className="mr-16"></span>
                <Toggle checked={rowHeight === compactRowHeight} onChange={toggleRowHeight} />
                <span className="pl-8" />
                <Text size="sm" weight="medium">
                  Compact mode
                </Text>
              </div>
            </div>
            <div
              className="pl-0 pr-0"
              style={{ display: selectedTab === processedText ? "none" : "initial" }}
              onMouseUp={(e) => {
                if (!["ag-input-field-input ag-checkbox-input"].includes(e.target.className) && !uiDisabled) {
                  updateDifferences(selectedAggregators);
                }
              }}
            >
              <Table
                columnDefs={unprocessedRows.columnDefs}
                rowData={unprocessedRows.rows}
                ref={unprocessedTableRef}
                rowSelection={"multiple"}
                autoSizeColumns={true}
                rowHeight={compactRowHeight}
                headerHeight={compactHeaderHeight}
              />
            </div>
            <div className="pl-0 pr-0" style={{ display: selectedTab === unprocessedText ? "none" : "initial" }}>
              <Table
                columnDefs={processedRows.columnDefs}
                rowData={processedRows.rows}
                ref={processedTableRef}
                rowSelection={"multiple"}
                rowClassRules={{
                  "manually-matched-row": (params) => Object.keys(params.data).includes("Matching Rule") && !params.data["Matching Rule"],
                }}
                autoSizeColumns={true}
                rowHeight={compactRowHeight}
                headerHeight={compactHeaderHeight}
                masterDetail={true}
                detailCellRendererParams={{
                  detailGridOptions: {
                    columnDefs: unmatchedColumns,
                  },
                  getDetailRowData: async (params) => {
                    const rows = await onClickExpandRows(params);
                    params.successCallback(rows);
                  },
                }}
              />
            </div>
          </>
        </ManageLayout>
        <AnimatedPanelToggleButton open={recDetailsOpen !== null} onClick={() => setRecDetailsOpen(recDetailsOpen !== null ? null : "all")} />
        <RecSidePanel rowGroupDetailsOpen={rowGroupDetailsOpen} recDetailsOpen={recDetailsOpen !== null}>
          <RowGroupDetailsPanel
            processedTableRef={processedTableRef}
            updateTable={() => updateTable(selectedTab, selectedSubAccount, allRowsMatched)}
            rowGroupDetailsOpen={rowGroupDetailsOpen}
            setRowGroupDetailsOpen={setRowGroupDetailsOpen}
            selectedRowGroups={selectedRowGroups}
          />
          <RecDetailsPanel
            rec={rec}
            date={new Date(rec.startDate)}
            recType={recType}
            account={selectedAccount}
            recDetailsOpen={recDetailsOpen}
            setRecDetailsOpen={setRecDetailsOpen}
            subAccounts={subAccounts}
            rowCounts={rowCounts}
            uploadedFiles={uploadedFiles}
            updateUrl={(subAccount, tab) => {
              replaceUrl({ ...existingParams, subAccId: subAccount.id, tab: tab });
              selectSubAccountAndTab(subAccount, tab);
              refreshRowCounts(subAccount);
            }}
            uiDisabled={uiDisabled}
          />
        </RecSidePanel>
      </>
    );
  }
);

export { OpenRecView };
