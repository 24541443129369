import { ReactComponent as PointInTimeIcon } from "../../icons/point-in-time-rec.svg";
import { ReactComponent as SequentialIcon } from "../../icons/sequential-rec.svg";

const RecModeIcon = ({ type, className }) => {
  return type ? (
    type.toLowerCase() === "sequential" ? (
      <div title="Rolling rec">
        <SequentialIcon className={className} />
      </div>
    ) : type.toLowerCase() === "ad_hoc" ? (
      <div title="Point-in-time rec">
        <PointInTimeIcon className={className} />
      </div>
    ) : (
      ""
    )
  ) : (
    ""
  );
};

export { RecModeIcon };
