import { observer } from "mobx-react-lite";
import { Text } from "@fundrecs/ui-library";
import { AccordianPanel } from "../../../reusable/AccordianPanel/AccordianPanel";

const SidePanelSection = observer(({ sectionOpen = false, panelOpen, title, icon, accordianId, expandTo = "32rem", openOnClick = () => {}, children }) => {
  return (
    <>
      {panelOpen ? (
        <AccordianPanel
          title={
            <>
              {icon}
              <span className="ml-16"></span>
              <Text size="sm" weight="bold">
                {title}
              </Text>
            </>
          }
          elementId={accordianId}
          expandTo={expandTo}
          openDefault={sectionOpen}
        >
          <div style={{ overflowY: "scroll", height: expandTo, margin: "16px" }}>{children}</div>
        </AccordianPanel>
      ) : (
        <div className="pl-16 pt-16 pb-16" style={{ borderBottom: "1px solid #E6E8EB", cursor: "pointer", color: "#6A7388" }} onClick={openOnClick}>
          {icon}
        </div>
      )}
    </>
  );
});

export { SidePanelSection };
