import { Text, Button, IconCheck, IconCloseRemove } from "@fundrecs/ui-library";
import { AuthWrapper } from "../AuthorizationWrapper.js";
import { AUTHORITIES } from "../../utils/enums.js";
import { Spinner } from "../layout/Spinner.js";
import { MatchingOptions } from "./matchingRules/MatchingOptions.js";
import { useStore } from "../../store/Store.js";

const PendingRecToolbar = ({ teamId, rejectRec, rejectApproveDisabled, approveRec }) => {
  const { rolesStore } = useStore();
  return (
    <div className="mb-16">
      <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_REC_REJECT])}>
        <Button
          size="md"
          onClick={() => {
            rejectRec();
          }}
          disabled={false}
          color={"danger"}
        >
          <Text size="sm" weight="medium">
            Reject
          </Text>
        </Button>
      </AuthWrapper>
      <span className="pr-8"></span>
      <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_REC_APPROVE])}>
        <Button
          size="md"
          onClick={() => {
            approveRec();
          }}
          disabled={rejectApproveDisabled}
          color={"success-primary"}
        >
          <IconCheck className={`btn-md-svg`} />
          <Text size="sm" weight="medium">
            Approve
          </Text>
        </Button>
      </AuthWrapper>
    </div>
  );
};

const OpenRecToolbar = ({
  selectedTab,
  processedRowButtonsDisabled,
  removeMatchedRows,
  loading,
  submitRec,
  submitRecDisabled,
  uiDisabled,
  setMatchingRulesRunning,
  rec,
  pollRecStatus,
  updateMatchPreview,
  setMatchingRuleId,
  setManualCategorizationOptions,
  matchingRules,
  subAccountId,
  teamId,
  gridRef,
  updateTable,
}) => {
  const { rolesStore } = useStore();

  return (
    <>
      {selectedTab === "Unprocessed" ? (
        <>
          <MatchingOptions
            gridRef={gridRef}
            uiDisabled={uiDisabled}
            setMatchingRulesRunning={setMatchingRulesRunning}
            rec={rec}
            pollRecStatus={pollRecStatus}
            updateMatchPreview={updateMatchPreview}
            setMatchingRuleId={setMatchingRuleId}
            setManualCategorizationOptions={setManualCategorizationOptions}
            matchingRules={matchingRules}
            subAccountId={subAccountId}
          />
          <span className="pr-8"></span>
        </>
      ) : (
        <>
          <span className="pr-8"></span>
          <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_ROW_GROUP_REMOVE])}>
            <Button
              size="md"
              onClick={() => {
                removeMatchedRows();
              }}
              disabled={processedRowButtonsDisabled}
              color="danger"
            >
              <>
                {loading ? <Spinner color="white" backgroundColor="red" /> : <IconCloseRemove className={`btn-md-svg`} />}
                <Text size="sm" weight="medium">
                  Remove processed rows
                </Text>
              </>
            </Button>
          </AuthWrapper>
        </>
      )}
      <span className="pr-8"></span>
      <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_REC_SUBMIT])}>
        <Button
          size="md"
          onClick={() => {
            submitRec();
          }}
          disabled={submitRecDisabled}
          color={"success-primary"}
        >
          <Text size="sm" weight="medium">
            Submit Rec
          </Text>
        </Button>
      </AuthWrapper>
    </>
  );
};

export { PendingRecToolbar, OpenRecToolbar };
