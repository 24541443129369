import { observer } from "mobx-react-lite";
import { Button, Text, modalInstance } from "@fundrecs/ui-library";
import { SidePanelSection } from "./SidePanelSection";
import { ReactComponent as UploadsIcon } from "../../../../icons/uploads.svg";
import { UploadedFilesList } from "../../UploadedFilesList";
import { MODALS } from "../../../../utils/enums";

const UploadedFilesSection = observer(({ panelOpen, uploadedFiles, openOnClick, uiDisabled }) => {
  return (
    <>
      <SidePanelSection
        panelOpen={panelOpen}
        sectionOpen={panelOpen === "uploads"}
        title="Uploads"
        icon={<UploadsIcon />}
        accordianId={"side-panel-uploads-accordian"}
        openOnClick={openOnClick}
      >
        <div className="d-flex justify-content-end mb-24" style={{ color: "white" }}>
          <Button
            disabled={uiDisabled}
            onClick={() => {
              modalInstance(MODALS.MANUAL_UPLOAD).show();
            }}
          >
            <UploadsIcon className="btn-sm-svg" />
            <Text size="sm">Upload file</Text>
          </Button>
        </div>
        <UploadedFilesList uploadedFiles={uploadedFiles} />
      </SidePanelSection>
    </>
  );
});
export { UploadedFilesSection };
